import {ApiConstants} from '../api.constant';
import {environment} from '../../../environments/environment';
import {QueryParams} from '../../classes/query-params';
import {IResponseModel} from '../../models/interfaces/response-model.interface';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';

export abstract class ApiRequest {
    public apiMethod = ApiConstants.REQUEST_METHODS.GET;
    public additionalQueryParams = new QueryParams();

    // Definition of the structure of the returned data
    public get responseModel() {
        return this.model;
    }

    protected get urlComponents() {
        return [
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ...this.pathComponents
        ];
    };

    protected constructor(public pathComponents: string[] = [],
                          public model: IResponseModel) {
    }

    /**
     * Implement child specific code for query parameters
     */
    protected getQueryParams(): QueryParams {
        const queryParams = new QueryParams();
        queryParams.addObject(this.additionalQueryParams.getParams());
        return queryParams;
    }

    public get options(): ARRequestOptions {
        const queryParams = this.getQueryParams();

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = this.apiMethod;
        options.url = ARApiUrlBuilderService.urlFromComponents(this.urlComponents, queryParams.getParams());

        return options;
    }
}
