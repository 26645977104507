import {ApiRequest} from './api.request';
import {ERequestMethod} from '../../app.enums';

export class ApiDeleteRequest extends ApiRequest {
    public apiMethod = ERequestMethod.DELETE;

    constructor(public pathComponents: string[] = []) {
        super(pathComponents, null);
    }
}
