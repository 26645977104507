import {ApiDeleteRequest} from './api-delete.request';

export class ApiDeleteOneRequest extends ApiDeleteRequest {
    protected get urlComponents() {
        return [
            ...super.urlComponents,
            this.itemId
        ];
    };

    constructor(public pathComponents: string[] = [],
                public itemId: string) {
        super(pathComponents);
    }
}
