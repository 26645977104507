const RELAYTER_EU = 'relayter.eu';

export const PROJECT_VARIABLES = {
    environments: {
        LOCAL: 'local',
        DEVELOPMENT: 'development',
        DEVELOPMENT_CSD: 'development_csd',
        DEVELOPMENT_PD: 'development_pd',
        ACCEPTANCE: 'acceptance',
        PRODUCTION: 'production'
    },
    aws_domains: {
        LOCAL_DOMAIN: 'localhost',
        DEVELOPMENT: 'relayter-web-development',
        DEVELOPMENT_CSD: 'relayter-web-development-csd',
        DEVELOPMENT_PD: 'relayter-web-development-pd',
        ACCEPTANCE: 'relayter-web-acceptance',
        PRODUCTION: 'relayter-web-production'
    },
    api_server: {
        LOCAL: 'http://localhost:5500',
        DEVELOPMENT: 'https://api-development.relayter.com',
        DEVELOPMENT_CSD: 'https://relayter-node-success.herokuapp.com',
        DEVELOPMENT_PD: 'https://api-development-pd.relayter.com',
        ACCEPTANCE: 'https://api-acceptance.relayter.com',
        PRODUCTION: 'https://api.relayter.com'
    },
    bugsnag_key: {
        LOCAL: '92f9d3111088152ceaf82f7beda5e6b7',
        DEVELOPMENT: '92f9d3111088152ceaf82f7beda5e6b7',
        DEVELOPMENT_CSD: '92f9d3111088152ceaf82f7beda5e6b7',
        DEVELOPMENT_PD: '92f9d3111088152ceaf82f7beda5e6b7',
        ACCEPTANCE: '92f9d3111088152ceaf82f7beda5e6b7',
        PRODUCTION: '92f9d3111088152ceaf82f7beda5e6b7'
    },
    segment_api_key: {
        LOCAL: 'f5S6UjA3C7EO9BMgfdDYhMc3r5VsOIaC',
        DEVELOPMENT: 'f5S6UjA3C7EO9BMgfdDYhMc3r5VsOIaC',
        DEVELOPMENT_CSD: 'Fe23HcNon0WJtjoRplQYlOVPe21oSGGF',
        DEVELOPMENT_PD: 'Fe23HcNon0WJtjoRplQYlOVPe21oSGGF',
        ACCEPTANCE: 'Fe23HcNon0WJtjoRplQYlOVPe21oSGGF',
        PRODUCTION: 'Fe23HcNon0WJtjoRplQYlOVPe21oSGGF'
    },
    auth0_tenant: {
        LOCAL: RELAYTER_EU,
        DEVELOPMENT: RELAYTER_EU,
        DEVELOPMENT_CSD: RELAYTER_EU,
        DEVELOPMENT_PD: RELAYTER_EU,
        ACCEPTANCE: RELAYTER_EU,
        PRODUCTION: RELAYTER_EU
    },
    auth0_audience: {
        LOCAL: 'http://localhost:5500/api',
        DEVELOPMENT: 'https://development.relayter.com/api',
        DEVELOPMENT_CSD: 'https://development-csd.relayter.com/api',
        DEVELOPMENT_PD: 'https://development-pd.relayter.com/',
        ACCEPTANCE: 'https://acceptance.relayter.com/api',
        PRODUCTION: 'https://app.relayter.com/api'
    },
    oauth_client_id: 'Rjq5B4os4B2DfR3nq199xVhwb9n4l8kE',
};
