import {DestroyRef, inject, Injectable} from '@angular/core';
import {
    ARApiError,
    ARApiRequestService,
    ARPagedResponseDataModel,
    ARPagedResponseModel,
    ARRequestOptions,
    ARResponseModel
} from '@relayter/core';
import {Deserialize, DeserializeInto} from 'cerialize';
import {Subscriber} from 'rxjs';
import {IResponseModel} from '../../models/interfaces/response-model.interface';

@Injectable()
export class BaseApiRequestService extends ARApiRequestService {
    protected destroyRef: DestroyRef = inject(DestroyRef);

    /**
     * Handle an error and logout if needed
     * @param error
     * @param obs
     */
    public handleError(error: ARApiError, obs?: any): void {
        if (obs) {
            obs.error(error);
            obs.complete();
        }
    }

    /**
     * Handle the detail response of a single detail model
     * @param {ARRequestOptions} options
     * @param {Subscriber<T>>} obs
     * @param {IResponseModel} model
     * @param {boolean} nullable
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected handleDetailResponse<T>(options: ARRequestOptions, obs: Subscriber<T>, model: IResponseModel, nullable = false): void {
        this.doRequest(options).subscribe({
            next: (res: ARResponseModel) => {
                if (nullable && Object.keys(res.data).length === 0) {
                    obs.next(null);
                } else {
                    obs.next(Deserialize(res.data, model));
                }
                obs.complete();
            },
            error: (err: ARApiError) => {
                this.handleError(err, obs);
            }
        });
    }

    /**
     * Handle the paged response of a paged call and map the response to the preferred models
     * @param {ARRequestOptions} options
     * @param {Subscriber<ARPagedResponseDataModel<T>>} obs
     * @param {IResponseModel} model
     */
    protected handlePagedResponse<T>(options: ARRequestOptions,
                                     obs: Subscriber<ARPagedResponseDataModel<T>>,
                                     model: IResponseModel): void {
        this.doPagedRequest(options).subscribe({
            next: (res: ARPagedResponseModel) => {
                const data: ARPagedResponseDataModel<T> = new ARPagedResponseDataModel<T>();
                data.total = res.data.total;
                data.hasNext = res.data.hasNext;

                DeserializeInto(res.data.items, model, data.items);

                obs.next(data);
                obs.complete();
            },
            error: (err: ARApiError) => {
                // real app breaking error log to bugsnag?
                this.handleError(err, obs);
            }
        });
    }

    /**
     * Handle No Error Response in this function to prevent duplicate code
     * @param options
     * @param obs
     */
    protected handleNoErrorResponse(options: ARRequestOptions, obs: Subscriber<boolean>): void {

        this.doRequest(options).subscribe({
            next: () => {
                obs.next(true);
                obs.complete();
            },
            error: (err: ARApiError) => {
                this.handleError(err, obs);
            }
        });
    }

    /**
     * Handle the array response and map the response to the preferred models
     * @param {ARRequestOptions} options
     * @param {Subscriber<ARPagedResponseDataModel<T>>} obs
     * @param {Function} model
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected handleArrayResponse<T>(options: ARRequestOptions, obs: Subscriber<ARPagedResponseDataModel<T>>, model: Function): void {
        this.doRequest(options).subscribe({
            next: (res: ARPagedResponseModel) => {
                const data: ARPagedResponseDataModel<T> = new ARPagedResponseDataModel<T>();
                DeserializeInto(res.data, model, data.items);
                obs.next(data);
                obs.complete();
            },
            error: (err: ARApiError) => {
                this.handleError(err, obs);
            }
        });
    }

    /**
     * Handle a response which is an array of strings (no paging and models)
     * @param options
     * @param obs
     * @protected
     */
    protected handleStringArrayResponse(options: ARRequestOptions, obs: Subscriber<string[]>): void {
        this.doRequest(options).subscribe({
            next: (res: ARPagedResponseModel<string>) => {
                obs.next(res.data.items);
                obs.complete();
            },
            error: (err: ARApiError) => {
                this.handleError(err, obs);
            }
        });
    }
}
