import {Pipe, PipeTransform} from '@angular/core';
import {AppConstants} from '../app.constants';
import {environment} from '../../environments/environment';

const BASE_DATE_FORMATS = { // localized date formats
    TIME: {hour: '2-digit', minute: '2-digit'}, // 23:59
    DAY_MONTH_YEAR: {day: 'numeric', month: 'numeric', year: 'numeric'}, // 3 3 2022
    DAY_MONTH_YEAR_SHORT: {day: '2-digit', month: 'short', year: 'numeric'}, // 03 Mar 2022
    DAY_MONTH_TIME_SHORT: {day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit'}, // 03 Mar, 23:59
    DAY_MONTH_YEAR_TIME_SHORT: {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'}, // 03 Mar 2022, 23:59
    DAY_MONTH_YEAR_TIME_SEC_FRAC: {day: '2-digit', month: 'short',
        year: 'numeric', hour: '2-digit', minute: '2-digit', second: 'numeric', fractionalSecondDigits: 3}, // 03 Mar 2022, 23:59.10
    DAY_MONTH_YEAR_LONG: {day: 'numeric', month: 'long', year: 'numeric'}, // 3 March 2022
    MONTH_YEAR_LONG: {month: 'long', year: 'numeric'}, // March 2022
    MONTH_YEAR_SHORT: {month: 'short', year: 'numeric'} // Mar 2022
};

export const RELAYTER_DATE_FORMATS = { // Skipped parse format
    parse: {
        dateInput: BASE_DATE_FORMATS.DAY_MONTH_YEAR
    },
    display: {
        dateInput: BASE_DATE_FORMATS.DAY_MONTH_YEAR_SHORT, // Handle format based on locale
        monthYearLabel: BASE_DATE_FORMATS.MONTH_YEAR_SHORT, // Displayed value in the MatDatePicker (month/year selection)
        dateA11yLabel: BASE_DATE_FORMATS.DAY_MONTH_YEAR_LONG, // Accessibility
        monthYearA11yLabel: BASE_DATE_FORMATS.MONTH_YEAR_LONG // Accessibility
    },
};

@Pipe({name: 'RLDatePipe'})
export class RLDatePipe implements PipeTransform {
    public static readonly dateFormats = {
        DEFAULT: BASE_DATE_FORMATS.DAY_MONTH_YEAR_SHORT,
        TABLE_DATERANGE: BASE_DATE_FORMATS.DAY_MONTH_YEAR_SHORT,
        TABLE_DETAILED: BASE_DATE_FORMATS.DAY_MONTH_YEAR_TIME_SHORT,
        AUDIT_TRAIL: BASE_DATE_FORMATS.DAY_MONTH_YEAR_TIME_SEC_FRAC,
        STICKY_DATE: BASE_DATE_FORMATS.DAY_MONTH_YEAR_SHORT,
        STICKY_COMMENT: BASE_DATE_FORMATS.DAY_MONTH_TIME_SHORT,
        STICKY_CHANGELOG: BASE_DATE_FORMATS.DAY_MONTH_TIME_SHORT,
        NOTIFICATION_TIME: BASE_DATE_FORMATS.TIME,
        NOTIFICATION_FULL: BASE_DATE_FORMATS.DAY_MONTH_YEAR_TIME_SHORT
    };

    private static readonly LOCALE = navigator.language || localStorage.getItem(AppConstants.LOCALSTORAGE_LOCALE) || environment.DEFAULT_LOCALE;
    private static privatePipe = new RLDatePipe();

    /** See {@link transform}
     * @param {any} [value] The date expression: a `Date` object,  a number, or an ISO string. If not provided, null value is returned
     * @param {string} [format] The date/time components to include. Defaults to {@link dateFormats.DEFAULT}
     * @param {string} [timezone] A timezone offset (such as `'+0430'`)
     * @param {string} [locale] A locale code for the locale format rules to use.
     * @returns {string} A date string in the desired format.
     */
    public static format(value?: any, format?:  Record<string, any>, timezone?: string, locale?: string): string {
        return RLDatePipe.privatePipe.transform(value, format, timezone, locale);
    }

    /**
     * PipeTransform implementation.
     * Formats the input value to a human-readable date according to the provided format
     * @param {any} [value] The date expression: a `Date` object,  a number, or an ISO string. If not provided, null value is returned
     * @param {Record<string, any>} [format] The date/time components to include. Defaults to {@link dateFormats.DEFAULT}
     * @param {string} [timezone] A timezone offset (such as `'+0430'`)
     * @param {string} [locale] A locale code for the locale format rules to use.
     * @returns {string} A date string in the desired format.
     */
    // "@ts-expect-error"
    public transform(value?: any, format?:  Record<string, any>, timezone?: string, locale?: string): string {
        const date = RLDatePipe.toDate(value);
        return value ? Intl.DateTimeFormat(locale || RLDatePipe.LOCALE, format).format(date) : null;
    }

    private static toDate(value: Date|number|string): Date {
        if (RLDatePipe.isDate(value)) return value as Date;

        if (typeof value === 'number' && !isNaN(value)) {
            return new Date(value);
        }

        const date = new Date(value);
        return RLDatePipe.isDate(date) ? date : null;
    }

    public static isDate(value: any): boolean {
        return value instanceof Date && !isNaN(value.valueOf());
    }

}
