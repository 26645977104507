/**
 *  error-formatter-util
 *  app-relayter
 *  Created by nandamhuis on 11/07/2017.
 *  Copyright © 2017 Creative Media Network. All rights reserved.
 */

import {ARApiError} from '@relayter/core';
import {ErrorConstants} from '../api/error.constants';

export class ErrorFormatterUtil {

    public static formatError(error: ARApiError): string {
        return error?.details || error?.message || ErrorConstants.INTERNAL_SERVER_ERROR_MESSAGE;
    }
}
