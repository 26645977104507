import {Cursor} from '../api/api-cursor';
import {TableSortOptions} from '../api/table-sort-options';
import {NewCursor} from '../api/new-api-cursor';
import {Serialize} from 'cerialize';
import {RLDatePipe} from '../pipes/rl-date.pipe';

export interface IQueryParam {
    queryParam: string;
    value: string | string[];
}

/**
 * Query parameters
 * Class to maintain request query parameters
 */
export class QueryParams {
    private params: Record<string, any> = {};
    private static ALLOWED_CURSOR_DATA_TYPES = ['number', 'date', 'objectid', 'boolean', 'string', 'enum'];

    /**
     * Set limit and offset parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {number} limit - Max number of results to return
     * @param {number} offset - Returns results from the offset
     * @returns {QueryParams}
     */
    public setLimitAndOffsetParams(limit?: number, offset?: number): QueryParams {
        this.addParam('limit', limit && limit > 0 ? limit : 100);
        this.addParam('offset', offset && offset > 0 ? offset : 0);
        this.addParam('includeTotals', true);

        return this;
    }

    /**
     * @deprecated {@link setSortingParams}
     * Set sort field and sort direction parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [sort] - The name of the field to sort on
     * @param {string} [sortDirection] - The direction of the sort (asc/desc)
     * @returns {QueryParams}
     */
    public setSortAndSortDirectionParams(sort?: string, sortDirection?: string): QueryParams {
        if (sort && sortDirection) {
            this.addParam('sort', sort);
            this.addParam('sortType', sortDirection.toUpperCase());
        }
        return this;
    }

    /**
     * Set sort fields and sort direction parameters. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {TableSortOptions} [tableSortOptions] - The sorting columns
     * @returns {QueryParams}
     */
    public setSortingParams(tableSortOptions?: TableSortOptions): QueryParams {
        if (tableSortOptions?.hasColumns) {
            this.addParam('sort', tableSortOptions.sortProperties);
            this.addParam('sortType', tableSortOptions.sortOrder.toUpperCase());
        }
        return this;
    }

    /**
     * Set values to search on. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} [search] = Value to search on within the results
     * @returns {QueryParams}
     */
    public setSearchParams(search?: string): QueryParams {
        if (search) {
            this.addParam('search', search);
        }
        return this;
    }

    /**
     * @deprecated Use {@link setNewCursor}
     * Set the cursor id and value
     * @param {Cursor} cursor - Cursor
     * @returns {QueryParams}
     */
    public setCursor(cursor?: Cursor): QueryParams {
        if (cursor?.duplicatedValues) this.addParam('cursorDuplicates', true);
        if (cursor?._id) this.addParam('cursorId', cursor._id);
        // Value only set for duplicated values
        if (cursor?.duplicatedValues) this.addParam('cursorValues', cursor.value);
        if (cursor?.dataType && QueryParams.ALLOWED_CURSOR_DATA_TYPES.includes(cursor.dataType)) this.addParam('cursorDataTypes', cursor.dataType);
        if (cursor) this.addParam('includeTotals', false);

        return this;
    }

    /**
     * Set the cursor id and values
     * @param {NewCursor} cursor - Cursor
     * @returns {QueryParams}
     */
    public setNewCursor(cursor?: NewCursor): QueryParams {
        if (cursor?.hasDuplicates) this.addParam('cursorDuplicates', true);
        if (cursor?._id) this.addParam('cursorId', cursor._id);
        // Value only set for duplicated values
        if (cursor?.hasDuplicates && cursor?.values.length) this.addParam('cursorValues', cursor.values);
        if (cursor?.dataTypes.length && cursor.dataTypes.every(dataType =>
            !dataType || QueryParams.ALLOWED_CURSOR_DATA_TYPES.includes(dataType))) this.addParam('cursorDataTypes', cursor.dataTypes);
        if (cursor) this.addParam('includeTotals', false);

        return this;
    }

    /**
     * Add a query parameter by key and values. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {string} key - Name of parameter
     * @param {any} value - The values of the parameter
     * @returns {QueryParams}
     */
    public addParam(key: string, value: any): QueryParams {
        if (key && (value || value === false || value === 0)) {
            this.params[key] = Array.isArray(value) ? value.map(value => this.formatValue(value)).join('|') : this.formatValue(value);
        }
        return this;
    }

    /**
     * Add parameters by the keys of an object. Returns this QueryParams for subsequents calls to maintain query parameters.
     * @param {object} object - Object to add to the query parameters
     * @returns {QueryParams}
     */
    public addObject(object: Record<string, any>): QueryParams {
        if (object) {
            for (const key of Object.keys(object)) {
                this.addParam(key, object[key]);
            }
        }
        return this;
    }

    /**
     * Get query parameters as an object
     * @returns {object}
     */
    public getParams(): Record<string, any> {
        return Serialize(this.params);
    }

    /**
     * Format outgoing value
     *
     * @param {any} value
     * @returns {any}
     * @private
     */
    private formatValue(value: any): any {
        if (RLDatePipe.isDate(value)) {
            return value.toISOString();
        }

        if (value === undefined) {
            return 'undefined';
        }

        return value;
    }
}
